import makeApiRequest from './makeApiCall';

export const getPayableVoucherList = async (data, otherOptions) => {
  return await makeApiRequest(
    `/accounting/payablevoucher`,
    'GET',
    null,
    data,
    false,
    '',
    otherOptions,
  );
};

export const getORListing = async (data, otherOptions) => {
  return await makeApiRequest(
    `/accounting/payablevoucher/or-lists`,
    'GET',
    null,
    data,
    false,
    '',
    otherOptions,
  );
};

export const getPVDrafts = async (data, otherOptions) => {
  return await makeApiRequest(
    `/accounting/payablevoucher/draft-lists`,
    'GET',
    null,
    data,
    false,
    '',
    otherOptions,
  );
};

export const submitPayableVoucher = async (data) => {
  return await makeApiRequest(
    `/accounting/payablevoucher/create-voucher`,
    'POST',
    null,
    data,
    true,
  );
};

export const submitPayableVoucherDraft = async (data) => {
  return await makeApiRequest(
    `/accounting/payablevoucher/draft-voucher`,
    'POST',
    null,
    data,
    true,
  );
};

export const submitORListing = async (data) => {
  return await makeApiRequest(
    `/accounting/payablevoucher/create-or`,
    'POST',
    null,
    data,
    false,
  );
};

export const updatePayableVoucher = async (data) => {
  return await makeApiRequest(
    `/accounting/payablevoucher/update-voucher/${data.payable_voucher_hdr_id}`,
    'POST',
    null,
    data,
    true,
  );
};

export const approvePayableVoucher = async (id) => {
  return await makeApiRequest(
    `accounting/payablevoucher/approve-voucher/${id}`,
    'POST',
    null,
    null,
    false,
  );
};

export const declinePayableVoucher = async (data) => {
  return await makeApiRequest(
    `/accounting/payablevoucher/return-to-maker-voucher/${data.payable_voucher_hdr_id}`,
    'POST',
    null,
    data,
    false,
  );
};

export const cancelPayableVoucher = async (data) => {
  return await makeApiRequest(
    `/accounting/payablevoucher/cancel-voucher/${data.payable_voucher_hdr_id}`,
    'POST',
    null,
    data,
    false,
  );
};

export const displayPayableVoucher = async (id) => {
  return await makeApiRequest(
    `/accounting/payablevoucher/show-voucher/${id}`,
    'GET',
    null,
    null,
    false,
  );
};

export const displayPayableVoucherDraft = async (id) => {
  return await makeApiRequest(
    `/accounting/payablevoucher/show-voucher-draft/${id}`,
    'GET',
    null,
    null,
    false,
  );
};

export const displayPayableVoucherPDF = async (id) => {
  return await makeApiRequest(
    `/accounting/payablevoucher/print-payable-voucher/${id}`,
    'POST',
    null,
    { payable_voucher_hdr_id: id },
    false,
  );
};

export const getPOList = async (data = null) => {
  return await makeApiRequest(
    `/v3/purchase/order/lists`,
    'GET',
    null,
    data,
    false,
  );
};

export const getPONonStockLists = async (data = null) => {
  return await makeApiRequest(
    `/accounting/payablevoucher/po-non-stock-lists`,
    'GET',
    data,
    false,
  );
};
export const getIRList = async (data = null) => {
  return await makeApiRequest(
    // `/inventoryreceipt/get/approved/receipts`,
    `/accounting/payablevoucher/ir-lists`,
    'POST',
    null,
    data,
    false,
  );
};

export const getCOAList = async (data = null) => {
  return await makeApiRequest(
    `/accounting/payablevoucher/coa-tag-dropdown`,
    'GET',
    null,
    data,
    false,
  );
};

export const getProgramList = async (data = null) => {
  return await makeApiRequest(`/programDB/list`, 'POST', null, data, false);
};

export const getMDList = async (data = null) => {
  return await makeApiRequest(`/search/customer/md`, 'POST', null, data, false);
};

export const getVendorList = async (data = null) => {
  return await makeApiRequest(
    `/vendor-db/vendor-dropdown`,
    'GET',
    null,
    data,
    false,
  );
};

export const getCoaDropdown = async (data) => {
  return await makeApiRequest(`/coa/drop-down`, 'POST', null, data, false);
};

export const getCostCenterDropdown = async (data) => {
  return await makeApiRequest(`/coa/drop-down`, 'POST', null, data, false);
};

export const toggleActive = async (data) => {
  return await makeApiRequest(`/coa/update/status`, 'POST', null, data, false);
};

export const exportPayableVoucher = async (data) => {
  return await makeApiRequest(
    `/accounting/payablevoucher/export`,
    'GET',
    null,
    data,
    false,
  );
};

export const exportPayableVoucherOR = async (data) => {
  return await makeApiRequest(
    `/accounting/payablevoucher/export/or`,
    'POST',
    null,
    data,
    false,
  );
};

export const exportPayableVoucherOrModal = async (data) => {
  return await makeApiRequest(
    `/accounting/payablevoucher/export-to-excel-pv-or`,
    'POST',
    null,
    data,
    false,
  );
};

export const getJournalVoucherCoaTags = async () => {
  return await makeApiRequest(`/jv/coa-tag-dropdown`, 'GET');
};
