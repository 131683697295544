import axios from 'axios';
import { store } from '../redux/store';

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_END_POINT,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: '',
  },
  timeout: 1000 * 2400, // 1000 = 1 sec
});

// intercept all endpoint and add authorization token
api.interceptors.request.use(function (config) {
  // set authorization token from store
  const { accessToken } = store.getState().auth;
  config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    // 403 expired token
    // 401 unauthorized
    if (error.response.status === 403 || error.response.status === 401) {
      window.location.href = '/unauthenticated';
    }

    return;
  },
);

/**
 * Serialize javascript object for sending to api
 * @param {Object} data
 * @returns {String}
 */
export function serialize(data) {
  return Object.keys(data)
    .map((keyName) => {
      return `${encodeURIComponent(keyName)}=${
        data[keyName] ? encodeURIComponent(data[keyName]) : ''
      }`;
    })
    .join('&');
}

/**
 * Method for making ajax calls to the site's api
 * @param {String} endpoint - the endpoint url
 * @param {String} method api methid POST | GET | DELETE
 * @param {String} token - Auth token for the end point
 * @param {Object|String} [data] - key:value pairs of the data to be sent to server
 * @param {Boolean} withFiles - has file attachments
 * @param {String} baseURL - override base url
 * @returns {Promise}
 */
export default async function makeApiRequest(
  endpoint,
  method,
  token = null, //not use
  data,
  withFiles,
  baseURL = null,
  otherOptions = {},
) {
  let request = {
    url: endpoint,
    method,
    data,
    ...otherOptions,
  };

  if (baseURL) {
    request.baseURL = baseURL;
  }

  if (method === 'GET') {
    request = { ...request, params: data };
  }

  if (endpoint.includes('auth')) {
    request.data = JSON.stringify(data);
  } else {
    if (withFiles) {
      let formData = new FormData();

      request.headers = {
        Authorization: `Bearer ${token}`,
      };

      if (data) {
        Object.entries(data).forEach(([key, value]) => {
          if (value instanceof FileList) {
            if (value[0]) {
              formData.append(key, value[0]);
            }
          } else if (typeof value === 'boolean') {
            formData.append(key, value ? 1 : 0);
          } else if (value === null) {
            formData.append(key, '');
          } else if (value instanceof Array) {
            formDataBuildArray(key, value, formData);
          } else {
            //if (value) { // removed this line the value 0 is being excluded
            formData.append(key, value);
            //}
          }
        });

        request.data = formData;
      }
    } else {
      request.headers = {
        Authorization: `Bearer ${token}`,
      };
      request.data = JSON.stringify(data);
    }
  }

  const result = await api(request)
    .then((response) => {
      if (response) {
        return response;
      } else {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
    })
    .catch((error) => {
      if (axios.isCancel) return 'request_cancelled';

      return error.response;
    });

  return result;
}

/**
 * Method on building Form Data for nested array values
 * @param {String} key - string
 * @param {Array} value - array of the key being pass
 * @param {FormData|Object} formData - Form Data Object where to append the value
 * @returns {Void}
 */
const formDataBuildArray = (key, value, formData) => {
  for (let index = 0; index < value.length; index++) {
    if (value[index] instanceof File) {
      formData.append(`${key}[]`, value[index]);
    } else if (value[index] instanceof Object) {
      Object.keys(value[index]).forEach((k) => {
        if (value[index][k] instanceof FileList) {
          if (value[index][k][0]) {
            formData.append(`${key}[${index}][${k}]`, value[index][k][0]);
          }
        } else if (value[index][k] instanceof Array) {
          formDataBuildArray(
            `${key}[${index}][${k}]`,
            value[index][k],
            formData,
          );
        } else if (value[index][k] === null) {
          formData.append(`${key}[${index}][${k}]`, '');
        } else {
          formData.append(`${key}[${index}][${k}]`, value[index][k]);
        }
      });
    } else if (value[index] instanceof Array) {
      formDataBuildArray(`${key}[${index}]`, value[index], formData);
    } else if (value[index] === null) {
      formData.append(`${key}[]`, '');
    } else {
      formData.append(`${key}[]`, value[index]);
    }
  }
};
