import makeApiRequest from './makeApiCall';

export const getCostCenter = async () => {
  return await makeApiRequest(`/cost_centers/dropdown`, 'GET');
};

export const getCoaCostCenter = async (data) => {
  return await makeApiRequest(`/coa/cost-center-drop-down`, 'POST', null, data);
};

export const getProgramDB = async (coa_id = '') => {
  return await makeApiRequest(`/programDB/dropdown`, 'GET', null, { coa_id });
};

export const getJournalVoucherCategories = async () => {
  return await makeApiRequest(`/jv/dropdown`, 'POST', null, {
    keyword: null,
    column_name: 'category',
    table_name: 'erp_accounting_journal_voucher_categories',
  });
};

export const getJournalVoucherPV = async (params) => {
  return await makeApiRequest(`/jv/pv-dropdown`, 'POST', null, params);
};

export const createJournalVoucher = async (form) => {
  return await makeApiRequest(`/jv/store`, 'POST', null, form, true);
};

export const getJournalVoucherList = async (params, otherOptions) => {
  return await makeApiRequest(
    `/jv/list`,
    'POST',
    null,
    params,
    false,
    '',
    otherOptions,
  );
};

export const getJournalVoucherExport = async (params) => {
  return await makeApiRequest(`/jv/list/export`, 'POST', null, params);
};

export const getJournalVoucherDetails = async (jv_id) => {
  return await makeApiRequest(`/jv/show`, 'POST', null, { jv_id });
};

export const postJournalVoucherStatus = async (form) => {
  return await makeApiRequest(`/jv/update`, 'POST', null, form, true);
};

export const getProgramDropdown = async () => {
  return await makeApiRequest(`/programDB/dropdown`, 'GET', null);
};

export const getJournalVoucherORList = async (params, otherOptions) => {
  return await makeApiRequest(
    `/jv/or/list`,
    'POST',
    null,
    params,
    false,
    '',
    otherOptions,
  );
};

export const getJournalVoucherPrint = async (jv_id) => {
  return await makeApiRequest(`/jv/print`, 'POST', null, { jv_id });
};

export const getJournalVoucherOrListingDropdownColumns = async (column) => {
  return await makeApiRequest(`/jv/or-dropdown`, 'POST', null, { column });
};

export const getJournalVoucherCoaTags = async () => {
  return await makeApiRequest(`/jv/coa-tag-dropdown`, 'GET');
};

export const getJournalVoucherORListingPrint = async (jv_id) => {
  return await makeApiRequest(`/jv/print/or`, 'GET', null, { jv_id });
};

export const getCoaAdjustingEntries = async () => {
  return await makeApiRequest(`/jv/coa-adjusting-entries`, 'GET');
};

export const getCustomerDropdown = async (params) => {
  return await makeApiRequest('/jv/customer-dropdown', 'GET', null, params);
};

export const getCustomerTradeReceivables = async (params) => {
  return await makeApiRequest(
    '/jv/trade-receivables-by-reference',
    'GET',
    null,
    params,
  );
};

export const getJournalVoucherDrafts = async (params, otherOptions) => {
  return await makeApiRequest(
    `/jv/drafts`,
    'POST',
    null,
    params,
    false,
    '',
    otherOptions,
  );
};

export const saveJournalVoucherDraft = async (form) => {
  return await makeApiRequest(`/jv/drafts/save`, 'POST', null, form, true);
};

export const updateJournalVoucherDraft = async (id, form) => {
  return await makeApiRequest(
    `/jv/drafts/save/${id}?_method=PUT`,
    'POST',
    null,
    form,
    true,
  );
};

export const getJournalVoucherDraft = async (draftId) => {
  return await makeApiRequest(`/jv/drafts/${draftId}`, 'GET', null, {
    draft_id: draftId,
  });
};

export const deleteJournalVoucherDraft = async (ids = []) => {
  return await makeApiRequest(`/jv/drafts`, 'DELETE', null, {
    ids,
  });
};

export const getMdDropdown = async (params) => {
  return await makeApiRequest(`/search/customer/md`, 'POST', null, params);
};
